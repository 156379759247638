/* Estilos para el botón de filtros */
.contenedor-boton-filtros {
  display: flex;
  justify-content: flex-end;
  margin: 20px;
}

.boton-filtros {
  padding: 8px 16px;
  background-color: #1976d2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.boton-filtros:hover {
  background-color: #1565c0;
}

/* Estilos para el modal de filtros */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-grande {
  width: 80%;
}

.modal-detalles {
  min-width: 600px;
}

.detalles-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}

.detalle-grupo {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.boton-cerrar {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #6c757d;
  color: white;
  cursor: pointer;
}

.boton-cerrar:hover {
  background-color: #5a6268;
}

.filtros-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 16px;
  margin: 20px 0;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-group label {
  font-weight: 500;
}

.form-group input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.rango-inputs {
  display: flex;
  gap: 8px;
}

.boton-limpiar-filtros,
.boton-aplicar-filtros,
.boton-cancelar-filtros {
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
}

.boton-limpiar-filtros {
  background-color: #f5f5f5;
  color: #333;
}

.boton-aplicar-filtros {
  background-color: #1976d2;
  color: white;
}

.boton-cancelar-filtros {
  background-color: #f44336;
  color: white;
}

/* Estilos para la tabla */
.tabla-container {
  margin: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.tabla-historial {
  width: 100%;
  border-collapse: collapse;
}

.tabla-header {
  background-color: #f5f5f5 !important;
}

.tabla-header th {
  color: #333 !important;
  font-weight: 600 !important;
  padding: 16px !important;
  white-space: nowrap;
  border-bottom: 2px solid #1976d2 !important;
}

.tabla-row {
  cursor: pointer;
}

.tabla-row:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.tabla-cell {
  padding: 12px 16px !important;
  border-bottom: 1px solid #e0e0e0 !important;
}

.tabla-cell-numeric {
  font-family: 'Roboto Mono', monospace !important;
}

.mensaje-sin-datos {
  padding: 24px;
  text-align: center;
  color: #666;
  font-style: italic;
} 

.descripcion-seccion {
  margin-top: 2rem;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 8px;
}