.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #1a1a1a;
  box-shadow: 0 2px 8px rgba(0,0,0,0.2);
}

.nav-brand {
  display: flex;
  align-items: center;
  color: #ffffff;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.nav-logo {
  height: 60px;
  width: auto;
  object-fit: contain;
}

.nav-buttons {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-left: auto;
}

.stocks-btn,
.entrada-btn,
.salida-btn,
.reportes-btn {
  padding: 8px 20px;
  background-color: #2c2c2c;
  color: #ffffff;
  border: 1px solid #404040;
  border-radius: 6px;
  cursor: pointer;
  font-size: 0.95rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  transition: all 0.2s ease;
}

.stocks-btn:hover,
.entrada-btn:hover,
.salida-btn:hover,
.entrada-btn.active,
.salida-btn.active,
.reportes-btn:hover,
.reportes-btn.active {
  background-color: #404040;
  border-color: #505050;
}

.entrada-dropdown,
.salida-dropdown,
.reportes-dropdown {
  position: relative;
}

.dropdown-content {
  position: absolute;
  right: 0;
  top: 120%;
  background-color: #2c2c2c;
  min-width: 180px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
  border-radius: 6px;
  padding: 8px 0;
  z-index: 1000;
  border: 1px solid #404040;
}

.dropdown-content a {
  color: #ffffff;
  padding: 12px 20px;
  text-decoration: none;
  display: block;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.9rem;
  transition: all 0.2s ease;
}

.dropdown-content a:hover {
  background-color: #404040;
}

.nested-dropdown {
  position: relative;
}

.nested-dropdown-content {
  display: none;
  position: absolute;
  left: 100%;
  top: 0;
  background-color: #2c2c2c;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border: 1px solid #404040;
  border-radius: 6px;
  padding: 8px 0;
}

.nested-dropdown-content a {
  color: #ffffff;
}

.nested-dropdown-content a:hover {
  background-color: #404040;
}

.nested-dropdown:hover .nested-dropdown-content {
  display: block;
}

.login-button-container {
  margin-left: 20px;
  padding-right: 20px;
}

.login-btn {
  background-color: #4a90e2;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.login-btn:hover {
  background-color: #357abd;
}

.admin-btn {
  padding: 8px 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.admin-btn:hover {
  background-color: #45a049;
}