.inventario-container {
  padding: 20px;
  max-width: 100%;
  overflow-x: auto;
}

.inventario-tabla {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.inventario-tabla thead {
  background-color: #f8f9fa;
}

.inventario-tabla th {
  padding: 15px;
  text-align: left;
  font-weight: 500;
  color: #333333;
  border-bottom: 2px solid #dee2e6;
  font-size: 0.95rem;
}

.inventario-tabla td {
  padding: 12px 15px;
  border-bottom: 1px solid #dee2e6;
  color: #333333;
}

.item-row {
  transition: all 0.2s ease;
}

.item-row:hover {
  background-color: #f8f9fa;
  cursor: pointer;
}

.total-row {
  background-color: #f8f9fa;
  font-weight: 500;
}

.total-row td {
  border-bottom: none;
  padding: 15px;
  color: #333333;
}

.alerta-vencimiento {
  background-color: #333333;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  position: relative;
}

.alerta-titulo {
  color: #ff4444;
  font-weight: bold;
  font-size: 1.1rem;
  margin-right: 10px;
}

.alerta-contenido {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

.alerta-proximos {
  color: #ffa726;
}

.alerta-vencidos {
  color: #ff4444;
}

.numero-alerta {
  font-weight: bold;
  font-size: 1.1rem;
}

.alerta-minimizar {
  position: absolute;
  right: 10px;
  top: 10px;
  background: none;
  border: none;
  color: #ffffff;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 5px;
}

.alerta-expandir {
  position: fixed;
  right: 20px;
  top: 80px;
  background-color: #333333;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header-container h1 {
  margin: 0;
  color: #333333;
  font-size: 1.8rem;
  font-weight: 600;
}

.boton-filtros, 
.boton-agregar {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.9rem;
  font-weight: 500;
}

.boton-filtros:hover, 
.boton-agregar:hover {
  background-color: #0056b3;
}

tr[style*="background-color: #ffebee"] {
  background-color: #fff5f5 !important;
  color: #dc3545 !important;
}

tr[style*="background-color: #fff3e0"] {
  background-color: #fff8e1 !important;
  color: #ffa000 !important;
}

@media (max-width: 768px) {
  .inventario-tabla {
    font-size: 0.9rem;
  }

  .inventario-tabla th,
  .inventario-tabla td {
    padding: 10px;
  }
}

/* Estilos para modales */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
}

/* Estilos base para todos los modales */
.modal {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 25px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  width: 90%;
  max-width: 1200px;
  max-height: 90vh;
  overflow-y: auto;
}

/* Estilos específicos para cada tipo de modal */
.modal-detalles,
.modal-edicion,
.modal-filtros {
  width: 90%;
  max-width: 1200px;
}

.modal-confirmacion {
  width: 400px;  /* Modal de confirmación más pequeño */
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
  color: #333333;
}

.descripcion-grupo {
  grid-column: span 3;  /* Que ocupe todo el ancho */
}

.descripcion-grupo textarea {
  width: 100%;
  min-height: 100px;
  resize: vertical;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 20px;
}

.boton-editar {
  background-color: #ffc107 !important; /* Amarillo para editar */
  color: #000000 !important;
  border: none !important;
  padding: 8px 20px !important;
  border-radius: 6px !important;
  font-weight: 500 !important;
}

.boton-editar:hover {
  background-color: #e0a800 !important;
}

.boton-salida {
  background-color: #17a2b8 !important; /* Azul claro para salida */
  color: #ffffff !important;
  border: none !important;
  padding: 8px 20px !important;
  border-radius: 6px !important;
  font-weight: 500 !important;
}

.boton-salida:hover {
  background-color: #138496 !important;
}

.boton-eliminar {
  background-color: #dc3545 !important; /* Rojo para eliminar */
  color: #ffffff !important;
  border: none !important;
  padding: 8px 20px !important;
  border-radius: 6px !important;
  font-weight: 500 !important;
}

.boton-eliminar:hover {
  background-color: #c82333 !important;
}

/* Estilo para el botón de cerrar */
.modal-buttons button:last-child {
  background-color: #6c757d !important; /* Gris para cerrar */
  color: #ffffff !important;
  border: none !important;
  padding: 8px 20px !important;
  border-radius: 6px !important;
  font-weight: 500 !important;
}

.modal-buttons button:last-child:hover {
  background-color: #5a6268 !important;
}

/* Efecto de transición suave para todos los botones */
.modal-buttons button {
  transition: all 0.2s ease !important;
  cursor: pointer !important;
}

/* Estilos responsivos */
@media (max-width: 1024px) {
  .form-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .descripcion-grupo {
    grid-column: span 2;
  }
}

@media (max-width: 768px) {
  .modal-edicion {
    width: 95%;
  }
  
  .form-grid {
    grid-template-columns: 1fr;
  }
  
  .descripcion-grupo {
    grid-column: span 1;
  }
}

/* Estilos específicos y prioritarios para el modal de edición */
.modal.modal-edicion {
  width: 90% !important;
  max-width: 1200px !important;
  min-width: 800px !important;
  margin: 20px auto;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 25px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* Estilos para el modal de filtros */
.modal-filtros {
  padding: 25px !important;
}

.modal-filtros h2 {
  color: #333333;
  margin-bottom: 20px;
  font-size: 1.5rem;
}

.filtros-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}

/* Estilos para los botones del modal de filtros */
.modal-filtros .modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 25px;
  padding-top: 20px;
  border-top: 1px solid #e0e0e0;
}

/* Botón de aplicar filtros */
.boton-aplicar-filtros {
  background-color: #28a745 !important; /* Verde para confirmar */
  color: #ffffff !important;
  border: none !important;
  padding: 8px 20px !important;
  border-radius: 6px !important;
  font-weight: 500 !important;
}

.boton-aplicar-filtros:hover {
  background-color: #218838 !important;
}

/* Botón de limpiar filtros */
.boton-limpiar-filtros {
  background-color: #6c757d !important; /* Gris para limpiar */
  color: #ffffff !important;
  border: none !important;
  padding: 8px 20px !important;
  border-radius: 6px !important;
  font-weight: 500 !important;
}

.boton-limpiar-filtros:hover {
  background-color: #5a6268 !important;
}

/* Botón de cancelar */
.boton-cancelar-filtros {
  background-color: #dc3545 !important; /* Rojo para cancelar */
  color: #ffffff !important;
  border: none !important;
  padding: 8px 20px !important;
  border-radius: 6px !important;
  font-weight: 500 !important;
}

.boton-cancelar-filtros:hover {
  background-color: #c82333 !important;
}

/* Estilos para los inputs del filtro */
.filtros-grid .form-group {
  margin-bottom: 15px;
}

.filtros-grid label {
  display: block;
  margin-bottom: 5px;
  color: #333333;
  font-weight: 500;
}

.filtros-grid input,
.filtros-grid select {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 0.9rem;
}

.filtros-grid input:focus,
.filtros-grid select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}

/* Estilos para el contenedor de rango */
.rango-inputs {
  display: flex;
  gap: 10px;
}

.rango-inputs input {
  width: calc(50% - 5px);
}

/* Efecto de transición suave para todos los botones */
.modal-filtros button {
  transition: all 0.2s ease !important;
  cursor: pointer !important;
}

/* Estilos específicos para los botones del modal de filtros */
.modal-filtros .modal-buttons button.boton-cancelar-filtros {
  background-color: #dc3545 !important; /* Rojo para cancelar */
  color: #ffffff !important;
  border: none !important;
  padding: 8px 20px !important;
  border-radius: 6px !important;
  font-weight: 500 !important;
}

.modal-filtros .modal-buttons button.boton-cancelar-filtros:hover {
  background-color: #c82333 !important;
}

/* Asegurarnos de que no haya otros estilos sobrescribiendo */
.modal-filtros .modal-buttons button:last-child {
  background-color: #dc3545 !important;
}

.modal-filtros .modal-buttons button:last-child:hover {
  background-color: #c82333 !important;
}

/* Estilos para el modal de agregar */
.modal.modal-agregar {
  width: 90% !important;
  max-width: 1200px !important;
  min-width: 800px !important;
  margin: 20px auto !important;
  background-color: #ffffff !important;
  border-radius: 8px !important;
  padding: 25px !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;
}

.modal.modal-agregar .form-grid {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr) !important;
  gap: 20px !important;
  margin-bottom: 20px !important;
}

.modal.modal-agregar .form-group {
  margin-bottom: 15px !important;
}

.modal.modal-agregar .descripcion-grupo {
  grid-column: span 3 !important;
}

.modal.modal-agregar .modal-buttons {
  display: flex !important;
  justify-content: flex-end !important;
  gap: 12px !important;
  margin-top: 20px !important;
  padding-top: 20px !important;
  border-top: 1px solid #e0e0e0 !important;
}

.modal.modal-agregar button {
  padding: 8px 20px !important;
  border-radius: 6px !important;
  font-weight: 500 !important;
  cursor: pointer !important;
}

/* Estilos para el formulario de productos */
.form-container {
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-top: 24px;
}

.form-title {
  margin-bottom: 24px;
}

.form-stepper {
  margin-bottom: 32px;
}

.success-message {
  text-align: center;
  color: #2e7d32;
  margin-bottom: 24px;
}

.button-container {
  display: flex;
  gap: 16px;
  margin-top: 24px;
}

.button-container button {
  flex: 1;
}

/* Estilos para los campos de formulario */
.form-field {
  margin-bottom: 16px;
}

/* Estilos para mensajes de error */
.error-text {
  color: #d32f2f;
  font-size: 0.75rem;
  margin-top: 3px;
}

/* Estilos para el diálogo */
.dialog-content {
  padding: 16px;
  min-width: 300px;
}

.dialog-actions {
  padding: 8px 24px;
}

/* Estilos responsivos */
@media (max-width: 768px) {
  .form-container {
    padding: 16px;
  }
  
  .button-container {
    flex-direction: column;
  }
}

.success-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  background-color: #f5f5f5;
}

.success-content {
  background: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 500px;
  width: 100%;
}

.success-icon {
  background-color: #4caf50;
  color: white;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 24px;
  font-size: 40px;
}

.success-title {
  color: #2e7d32;
  margin-bottom: 16px;
  font-size: 24px;
}

.success-message {
  color: #666;
  margin-bottom: 32px;
}

.success-buttons {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.primary-button {
  background-color: #1976d2;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.primary-button:hover {
  background-color: #1565c0;
}

.secondary-button {
  color: #1976d2;
  padding: 12px 24px;
  border: 2px solid #1976d2;
  border-radius: 4px;
  text-decoration: none;
  font-size: 16px;
  transition: background-color 0.3s;
}

.secondary-button:hover {
  background-color: rgba(25, 118, 210, 0.04);
}

/* Estilos para el contenedor de error */
.error-container {
  background-color: #ffebee;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-top: 24px;
  text-align: center;
}

.error-title {
  color: #d32f2f;
  margin-bottom: 16px;
  font-size: 24px;
}

.error-message {
  color: #666;
  margin-bottom: 24px;
}

.error-buttons {
  display: flex;
  justify-content: center;
  gap: 16px;
}

.error-button {
  background-color: #d32f2f;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.error-button:hover {
  background-color: #b71c1c;
}

/* Estilos responsivos para el contenedor de error */
@media (max-width: 768px) {
  .error-buttons {
    flex-direction: column;
  }
  
  .error-button {
    width: 100%;
  }
}

/* Estilos para las sugerencias de proveedores */
.proveedor-sugerencias {
  position: absolute;
  z-index: 1000;
  width: 100%;
  max-height: 200px;
  overflow: auto;
  margin-top: 4px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.sugerencia-item {
  padding: 8px 16px;
  cursor: pointer;
}

.sugerencia-item:hover {
  background-color: #f5f5f5;
}

/* Estilos para el contenedor de proveedores */
.proveedor-container {
  position: relative;
}

.pagination-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
}

.pagination-button {
  background-color: #1976d2; /* Color primario de Material-UI */
  color: white;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.2s ease;
  font-size: 16px;
}

.pagination-button:hover {
  background-color: #1565c0; /* Un tono más oscuro al hacer hover */
}

.pagination-number {
  padding: 8px 16px;
  background-color: white;
  border: 1px solid #1976d2;
  border-radius: 4px;
  min-width: 40px;
  text-align: center;
  color: #1976d2;
  font-weight: 500;
}