.inventario-container {
  padding: 20px;
  max-width: 100%;
  overflow-x: auto;
}

.inventario-tabla {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.inventario-tabla thead {
  background-color: #f8f9fa;
}

.inventario-tabla th {
  padding: 15px;
  text-align: left;
  font-weight: 500;
  color: #333333;
  border-bottom: 2px solid #dee2e6;
  font-size: 0.95rem;
}

.inventario-tabla td {
  padding: 12px 15px;
  border-bottom: 1px solid #dee2e6;
  color: #333333;
}

.item-row {
  transition: all 0.2s ease;
}

.item-row:hover {
  background-color: #f8f9fa;
  cursor: pointer;
}

.total-row {
  background-color: #f8f9fa;
  font-weight: 500;
}

.total-row td {
  border-bottom: none;
  padding: 15px;
  color: #333333;
}

.alerta-vencimiento {
  background-color: #333333;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  position: relative;
}

.alerta-titulo {
  color: #ff4444;
  font-weight: bold;
  font-size: 1.1rem;
  margin-right: 10px;
}

.alerta-contenido {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

.alerta-proximos {
  color: #ffa726;
}

.alerta-vencidos {
  color: #ff4444;
}

.numero-alerta {
  font-weight: bold;
  font-size: 1.1rem;
}

.alerta-minimizar {
  position: absolute;
  right: 10px;
  top: 10px;
  background: none;
  border: none;
  color: #ffffff;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 5px;
}

.alerta-expandir {
  position: fixed;
  right: 20px;
  top: 80px;
  background-color: #333333;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header-container h1 {
  margin: 0;
  color: #333333;
  font-size: 1.8rem;
  font-weight: 600;
}

.boton-filtros, 
.boton-agregar {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.9rem;
  font-weight: 500;
}

.boton-filtros:hover, 
.boton-agregar:hover {
  background-color: #0056b3;
}

tr[style*="background-color: #ffebee"] {
  background-color: #fff5f5 !important;
  color: #dc3545 !important;
}

tr[style*="background-color: #fff3e0"] {
  background-color: #fff8e1 !important;
  color: #ffa000 !important;
}

@media (max-width: 768px) {
  .inventario-tabla {
    font-size: 0.9rem;
  }

  .inventario-tabla th,
  .inventario-tabla td {
    padding: 10px;
  }
}

/* Estilos para modales */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
}

/* Estilos base para todos los modales */
.modal {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 25px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  width: 90%;
  max-width: 1200px;
  max-height: 90vh;
  overflow-y: auto;
}

/* Estilos específicos para cada tipo de modal */
.modal-detalles,
.modal-edicion,
.modal-filtros {
  width: 90%;
  max-width: 1200px;
}

.modal-confirmacion {
  width: 400px;  /* Modal de confirmación más pequeño */
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
  color: #333333;
}

.form-group input, .form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.form-group textarea {
  resize: vertical;
  min-height: 100px;
}

.descripcion-grupo {
  grid-column: span 3;  /* Que ocupe todo el ancho */
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 20px;
}

.boton-editar {
  background-color: #ffc107 !important; /* Amarillo para editar */
  color: #000000 !important;
  border: none !important;
  padding: 8px 20px !important;
  border-radius: 6px !important;
  font-weight: 500 !important;
}

.boton-editar:hover {
  background-color: #e0a800 !important;
}

.boton-salida {
  background-color: #17a2b8 !important; /* Azul claro para salida */
  color: #ffffff !important;
  border: none !important;
  padding: 8px 20px !important;
  border-radius: 6px !important;
  font-weight: 500 !important;
}

.boton-salida:hover {
  background-color: #138496 !important;
}

.boton-eliminar {
  background-color: #dc3545 !important; /* Rojo para eliminar */
  color: #ffffff !important;
  border: none !important;
  padding: 8px 20px !important;
  border-radius: 6px !important;
  font-weight: 500 !important;
}

.boton-eliminar:hover {
  background-color: #c82333 !important;
}

/* Estilo para el botón de cerrar */
.modal-buttons button:last-child {
  background-color: #6c757d !important; /* Gris para cerrar */
  color: #ffffff !important;
  border: none !important;
  padding: 8px 20px !important;
  border-radius: 6px !important;
  font-weight: 500 !important;
}

.modal-buttons button:last-child:hover {
  background-color: #5a6268 !important;
}

/* Efecto de transición suave para todos los botones */
.modal-buttons button {
  transition: all 0.2s ease !important;
  cursor: pointer !important;
}

/* Estilos responsivos */
@media (max-width: 1024px) {
  .form-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .descripcion-grupo {
    grid-column: span 2;
  }
}

@media (max-width: 768px) {
  .modal-edicion {
    width: 95%;
  }
  
  .form-grid {
    grid-template-columns: 1fr;
  }
  
  .descripcion-grupo {
    grid-column: span 1;
  }
}

/* Estilos específicos y prioritarios para el modal de edición */
.modal.modal-edicion {
  width: 90% !important;
  max-width: 1200px !important;
  min-width: 800px !important;
  margin: 20px auto;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 25px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* Estilos para el modal de filtros */
.modal-filtros {
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
}

.filtros-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 16px;
  margin: 20px 0;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-group label {
  font-weight: 500;
}

.form-group input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.rango-inputs {
  display: flex;
  gap: 8px;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
}

.boton-limpiar-filtros,
.boton-aplicar-filtros,
.boton-cancelar-filtros {
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
}

.boton-limpiar-filtros {
  background-color: #f5f5f5;
  color: #333;
}

.boton-aplicar-filtros {
  background-color: #1976d2;
  color: white;
}

.boton-cancelar-filtros {
  background-color: #f44336;
  color: white;
}

/* Estilos para el contenedor de rango */
.rango-inputs {
  display: flex;
  gap: 10px;
}

.rango-inputs input {
  width: calc(50% - 5px);
}

/* Efecto de transición suave para todos los botones */
.modal-filtros button {
  transition: all 0.2s ease !important;
  cursor: pointer !important;
}

/* Estilos específicos para los botones del modal de filtros */
.modal-filtros .modal-buttons button.boton-cancelar-filtros {
  background-color: #dc3545 !important; /* Rojo para cancelar */
  color: #ffffff !important;
  border: none !important;
  padding: 8px 20px !important;
  border-radius: 6px !important;
  font-weight: 500 !important;
}

.modal-filtros .modal-buttons button.boton-cancelar-filtros:hover {
  background-color: #c82333 !important;
}

/* Asegurarnos de que no haya otros estilos sobrescribiendo */
.modal-filtros .modal-buttons button:last-child {
  background-color: #dc3545 !important;
}

.modal-filtros .modal-buttons button:last-child:hover {
  background-color: #c82333 !important;
}

/* Estilos para el modal de agregar */
.modal.modal-agregar {
  width: 90% !important;
  max-width: 1200px !important;
  min-width: 800px !important;
  margin: 20px auto !important;
  background-color: #ffffff !important;
  border-radius: 8px !important;
  padding: 25px !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;
}

.modal.modal-agregar .form-grid {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr) !important;
  gap: 20px !important;
  margin-bottom: 20px !important;
}

.modal.modal-agregar .form-group {
  margin-bottom: 15px !important;
}

.modal.modal-agregar .descripcion-grupo {
  grid-column: span 3 !important;
}

.modal.modal-agregar .modal-buttons {
  display: flex !important;
  justify-content: flex-end !important;
  gap: 12px !important;
  margin-top: 20px !important;
  padding-top: 20px !important;
  border-top: 1px solid #e0e0e0 !important;
}

.modal.modal-agregar button {
  padding: 8px 20px !important;
  border-radius: 6px !important;
  font-weight: 500 !important;
  cursor: pointer !important;
}

/* Estilos mejorados para el modal de detalles */
.modal.modal-detalles {
  width: 95% !important; /* Más ancho */
  max-width: 1800px !important; /* Máximo ancho aumentado */
  min-width: 800px !important; /* Ancho mínimo */
  height: 90vh !important; /* Altura fija */
  margin: 20px auto !important;
  padding: 30px !important;
  background-color: #ffffff !important;
  border-radius: 12px !important;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15) !important;
  overflow-y: auto !important;
}

.modal-detalles .detalles-grid {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr) !important;
  gap: 25px !important;
  margin: 20px 0 !important;
}

.modal-detalles .detalle-grupo {
  background: #f8f9fa !important;
  padding: 20px !important;
  border-radius: 8px !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05) !important;
}

.modal-detalles h2 {
  color: #2c3e50;
  font-size: 2rem;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid #eee;
}

.detalles-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-bottom: 2rem;
}

.detalle-grupo {
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.detalle-grupo p {
  margin: 0.5rem 0;
  line-height: 1.5;
}

.descripcion-seccion {
  margin-top: 2rem;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 8px;
}

/* Ajuste para el overlay */
.modal-overlay {
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(2px);
}

/* Estilos responsivos */
@media (max-width: 1024px) {
  .detalles-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .modal-detalles {
    width: 90%;
    padding: 1.5rem;
  }
}

@media (max-width: 768px) {
  .detalles-grid {
    grid-template-columns: 1fr;
  }
  
  .detalle-grupo strong {
    min-width: 120px;
  }
}

.modal-grande {
  width: 80%; /* Aumenta el ancho del modal */
  max-width: 1200px; /* Establece un ancho máximo */
  max-height: 90vh; /* Altura máxima del 90% de la ventana */
  overflow-y: auto; /* Permite scroll si el contenido es muy largo */
}

.detalles-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-bottom: 2rem;
}

.detalle-grupo {
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.detalle-grupo p {
  margin: 0.5rem 0;
  line-height: 1.5;
}

.descripcion-seccion {
  margin-top: 2rem;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.modal-detalles .modal-buttons {
  display: flex !important;
  justify-content: flex-end !important;
  gap: 12px !important;
  margin-top: 30px !important;
  padding-top: 20px !important;
  border-top: 1px solid #e0e0e0 !important;
  position: sticky !important;
  bottom: 0 !important;
  background-color: white !important;
}

.modal-detalles .boton-eliminar {
  background-color: #dc3545 !important;
  color: white !important;
}

.modal-detalles .boton-eliminar:hover {
  background-color: #c82333 !important;
}

.modal-detalles .boton-cerrar {
  background-color: #6c757d !important;
  color: white !important;
}

.modal-detalles .boton-cerrar:hover {
  background-color: #5a6268 !important;
}

.codigos-internos-tabla {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.codigos-internos-tabla th,
.codigos-internos-tabla td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.codigos-internos-tabla td:last-child {
  text-align: right;
  width: 150px;  /* Ancho fijo para la columna del botón */
}

.boton-pdf {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.boton-pdf:hover {
  background-color: #0056b3;
}

/* Estilos para el botón de filtros */
.contenedor-boton-filtros {
  display: flex;
  justify-content: flex-end;
  margin: 20px;
}

.boton-filtros {
  padding: 8px 16px;
  background-color: #1976d2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.boton-filtros:hover {
  background-color: #1565c0;
}

/* Estilos para la tabla */
.tabla-container {
  margin: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.tabla-header {
  background-color: #f5f5f5 !important;
}

.tabla-header th {
  color: #333 !important;
  font-weight: 600 !important;
  padding: 16px !important;
  white-space: nowrap;
  border-bottom: 2px solid #1976d2 !important;
}

.tabla-row {
  transition: background-color 0.2s;
}

.tabla-row:hover {
  background-color: #f5f5f5 !important;
  cursor: pointer;
}

.tabla-cell {
  padding: 12px 16px !important;
  border-bottom: 1px solid #e0e0e0 !important;
}

.tabla-cell-numeric {
  font-family: 'Roboto Mono', monospace !important;
}

.mensaje-sin-datos {
  padding: 24px;
  text-align: center;
  color: #666;
  font-style: italic;
}

.boton-guardar {
  background-color: #4CAF50;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
}

.boton-guardar:hover {
  background-color: #45a049;
}
