.table-container {
  overflow-x: auto;
  margin: 20px;
}

.permissions-table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.permissions-table th,
.permissions-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.permissions-table th {
  background-color: #f8f9fa;
  font-weight: 600;
  color: #333;
}

.permissions-table tr:hover {
  background-color: #f5f5f5;
}

.checkbox {
  width: 18px;
  height: 18px;
  cursor: pointer;
  accent-color: #2563eb;
}

.button-container {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
}

.create-user-button {
  background-color: #2563eb;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.create-user-button:hover {
  background-color: #1d4ed8;
}

.export-button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.export-button:hover {
  background-color: #45a049;
}

/* Responsive design */
@media screen and (max-width: 600px) {
  .permissions-table {
    font-size: 14px;
  }
  
  .permissions-table th,
  .permissions-table td {
    padding: 8px 10px;
  }

  .create-user-button {
    padding: 8px 16px;
    font-size: 12px;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-content h2 {
  margin-bottom: 20px;
  color: #333;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #555;
}

.form-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.cancel-button {
  background-color: #dc2626;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.cancel-button:hover {
  background-color: #b91c1c;
}

@media screen and (max-width: 600px) {
  .modal-content {
    margin: 20px;
    padding: 15px;
  }
} 
.notification-overlay {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2000;
  padding: 15px 30px;
  border-radius: 5px;
  animation: slideDown 0.5s ease-in-out;
}

.notification-content {
  color: white;
  font-weight: bold;
}

.error {
  background-color: #ff4444;
}

.success {
  background-color: #00C851;
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%) translateX(-50%);
  }
  100% {
    transform: translateY(0) translateX(-50%);
  }
}

.export-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px 0;
}

.export-options button {
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.export-options button:hover {
  background-color: #45a049;
}