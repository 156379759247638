.App {
  min-height: 100vh;
  background-color: #f5f5f5;
  color: #333333;
}

.content {
  padding: 2rem;
}

.inventario-container {
  padding: 20px;
}

.inventario-tabla {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.inventario-tabla th,
.inventario-tabla td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.inventario-tabla th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.inventario-tabla tr:hover {
  background-color: #f5f5f5;
}

.boton-agregar {
  position: fixed;
  bottom: 30px;
  right: 30px;
  padding: 15px 25px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  transition: all 0.3s ease;
}

.boton-agregar:hover {
  background-color: #45a049;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal h2 {
  margin-top: 0;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.modal-buttons button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-buttons button[type="submit"] {
  background-color: #4CAF50;
  color: white;
}

.modal-buttons button[type="button"] {
  background-color: #ddd;
}

.modal-buttons button:hover {
  opacity: 0.9;
}

/* Estilos para tablas y elementos comunes */
table {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

th, td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

th {
  background-color: #f8f9fa;
  color: #333333;
  font-weight: 500;
}

tr:hover {
  background-color: #f8f9fa;
}

/* Estilos para botones */
button {
  background-color: #007bff;
  color: white;
  border: 1px solid #0056b3;
  padding: 8px 16px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
}

button:hover {
  background-color: #0056b3;
}

/* Estilos para inputs */
input, select, textarea {
  background-color: #ffffff;
  border: 1px solid #ced4da;
  color: #333333;
  padding: 8px 12px;
  border-radius: 4px;
  width: 100%;
}

input:focus, select:focus, textarea:focus {
  outline: none;
  border-color: #80bdff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

/* Estilos para modales */
.modal-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

.modal {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* Estilos para títulos */
h1, h2, h3 {
  color: #333333;
  margin-bottom: 1rem;
}
